import { getImageData } from "gatsby-plugin-image"

function urlBuilder({ baseUrl, width, height, format, options }) {
    return `https://cdn.drinkyn.com/fit-in/${width}x${height}/${baseUrl}`
}

function urlBuilderPlazavea({ baseUrl, width, height, format, options }) {
    return `https://plazavea.vteximg.com.br/arquivos/ids/${baseUrl}-${width}-${height}`
}

export function getExampleImageData({ image, ...props }) {
    return getImageData({
        baseUrl: image.url,
        sourceWidth: image.width,
        sourceHeight: image.height,
        urlBuilder,
        pluginName: "gatsby-source-example",
        // If your host supports auto-format/content negotiation, pass this as the formats array
        formats: ["auto"],
        ...props,
    })
}

export function getExampleImageDataPlazavea({ image, ...props }) {
    return getImageData({
        baseUrl: image.url,
        sourceWidth: image.width,
        sourceHeight: image.height,
        urlBuilder: urlBuilderPlazavea,
        pluginName: "gatsby-source-example",
        // If your host supports auto-format/content negotiation, pass this as the formats array
        formats: ["auto"],
        ...props,
    })
}