import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { getExampleImageDataPlazavea } from "../../utils/urlBuilder"

export function PlazaveaImage({
    // Destructure the props that you are passing to the image data function
    image,
    width,
    height,
    layout,
    backgroundColor,
    sizes,
    aspectRatio,
    options,
    // Use ...rest for the GatsbyImage props
    ...props
}) {
    const imageData = getExampleImageDataPlazavea({
        image,
        width,
        height,
        layout,
        backgroundColor,
        sizes,
        aspectRatio,
        options,
    })

    // Pass the image data and spread the rest of the props
    return <GatsbyImage image={imageData} {...props} />
}