import React, { useState } from 'react';
import { Drawer } from 'vaul';
import axiosPrivate from '../../api/axios';
import useDebounce from '../../hooks/useDebounce';
import useSWR from 'swr';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { navigate } from 'gatsby';
import { formatNumber } from '../../utils/formatNumber';
import { XMarkIcon } from '@heroicons/react/24/outline';
import SkeletonProductSearch from '../SkeletonProductSearch';
import { PlazaveaImage } from '../PlazaveaImage';
import { DrinkynImage } from '../DrinkynImage';

const fetcher = url => axiosPrivate.get(url).then(res => res.data)

const DrawerSearch = (props) => {

    const { open, setOpen } = props
    const [inputValue, setInputValue] = useState('');
    const debouncedInputValue = useDebounce(inputValue);

    const { data, error, isLoading } = useSWR(
        debouncedInputValue ? `/products/search?search_text=${debouncedInputValue}&limit=20` : null,
        fetcher,
        {
            revalidateOnFocus: false,
            dedupingInterval: 500,
            refreshInterval: 0,
        }
    );

    const [isDragging, setIsDragging] = useState(false);
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

    const handleMouseDown = (e) => {
        setIsDragging(false);
        setStartPosition({ x: e.clientX, y: e.clientY });
    };

    const handleMouseMove = (e) => {
        const distance = Math.sqrt(
            Math.pow(e.clientX - startPosition.x, 2) + Math.pow(e.clientY - startPosition.y, 2)
        );

        if (distance > 5) {
            setIsDragging(true); // Considera como un arrastre si se supera el umbral
        }
    };

    const handleMouseUp = (slug) => {
        if (!isDragging) {
            onClick(slug)
        }
    };

    const onClick = (slug) => {
        navigate(`/${slug}`)
        setOpen(false)
    }
    return (
        <>

            <Drawer.Root open={open} onOpenChange={setOpen} repositionInputs={false} direction="right" >
                <Drawer.Portal>
                    <Drawer.Overlay className="fixed inset-0 z-[100] bg-black/40" />
                    <Drawer.Content aria-describedby={"Busca tus licores y vinos favoritos"} className="bg-white inset-y-0 right-0 z-[100] fixed outline-none  w-[410px] max-w-[90%] " style={{ '--initial-transform': 'calc(100% + 8px)' }}>
                        <Drawer.Title className='sr-only'>Buscador de productos</Drawer.Title>
                        <Drawer.Description className='sr-only'>"Busca tus licores y vinos favoritos"</Drawer.Description>
                        <div className='flex flex-col h-full max-h-full'>
                            <div className='flex items-center space-x-2 px-4 py-2 mt-2'>
                                <button type='button' onClick={() => { setOpen(false) }} className='flex items-center justify-center rounded-full bg-gray-100 w-8 h-8'>
                                    <ChevronLeftIcon className='w-4 h-4 stroke-black ' />
                                </button>
                                <div className='flex flex-1 items-center justify-center h-10 px-2 rounded-xl bg-gray-100'>
                                    <label htmlFor="search-product" className='sr-only'>Buscador de productos</label>
                                    <input id="search-product" autoFocus={true} className='flex-1 px-2 w-full h-full bg-transparent appearance-none outline-none' placeholder='¿Que quieres hoy?' value={inputValue} onChange={e => setInputValue(e.target.value)} />
                                    <button type='button' onClick={() => { setInputValue('') }} className='rounded-full h-6 w-6 hover:bg-gray-200 flex items-center justify-center leading-none' ><XMarkIcon className='w-5 h-5' /> <span className='sr-only'>Eliminar texto</span></button>
                                </div>
                            </div>
                            <div className="grow flex flex-col overflow-y-auto h-min max-h-min divide-y">
                                {isLoading && <>
                                    <SkeletonProductSearch />
                                    <SkeletonProductSearch />
                                    <SkeletonProductSearch />
                                    <SkeletonProductSearch />
                                </>}
                                {error && <div className='w-full h-32 flex items-center px-4 text-center text-gray-800'>Ups... hubo un error al obtener los datos, intentalo nuevamente</div>}
                                {
                                    data && data?.data && Array.isArray(data.data) && data.data.map((product, index) => {
                                        return <div role="button" tabIndex={0} onMouseDown={handleMouseDown}
                                            onMouseMove={handleMouseMove}
                                            onMouseUp={() => { handleMouseUp(product.slug) }} onKeyDown={() => { onClick(product.slug) }} onClick={null} className='px-2 py-2' key={index} style={{

                                            }}>
                                            <div className='flex  no-draggable'>
                                                <div className="w-24 h-24 no-draggable aspect-square"
                                                >
                                                    {product.cover?.provider === 'plazavea' ?
                                                        <PlazaveaImage
                                                            image={{ url: product?.cover?.name, placeholder: "BLURRED", width: product?.cover?.width, height: product?.cover?.height }}
                                                            loading="eager"
                                                            layout="constrained"
                                                            placeholder="blurred"
                                                            width={256}
                                                            height={256}
                                                            aspectRatio={1 / 1}
                                                            alt={product.name}
                                                            className="w-full"
                                                        /> :
                                                        <DrinkynImage
                                                            image={{ url: product?.cover?.name, placeholder: "BLURRED", width: product?.cover?.width, height: product?.cover?.height }}
                                                            loading="eager"
                                                            layout="constrained"
                                                            placeholder="blurred"
                                                            width={256}
                                                            height={256}
                                                            aspectRatio={1 / 1}
                                                            alt={product.name}
                                                            className="w-full"
                                                        />
                                                    }
                                                </div>
                                                <div className='p-2' >
                                                    <p className='leading-tight w-full font-semibold'>
                                                        {product.name}
                                                    </p>
                                                    <div className="h-6 flex justify-left items-center mb-2 gap-1 mt-2">
                                                        <span className="text-[15px] font-bold">S/{formatNumber(product.price_min, 2)}</span>
                                                        -
                                                        <span className="text-[15px] font-bold">S/{formatNumber(product.price_max, 2)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </Drawer.Content>
                </Drawer.Portal>
            </Drawer.Root >
        </>
    );
}

export default DrawerSearch 